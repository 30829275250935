import * as React from 'react';
import { Link } from 'gatsby';

import { Text, BlogImage, BlogLink, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import speechTherapy1 from '../../../images/educational-corner/blog31/logopedska-terapija-na-daljinu1.jpg';
import speechTherapy2 from '../../../images/educational-corner/blog31/logopedska-terapija-na-daljinu2.jpg';
import kokolingo from '../../../images/educational-corner/blog31/kokolingo.png';

const TeletherapyInEarlyIntervention = () => {
  return (
    <PageNew
      title="KOKOLINGO - Teleterapija kod rane intervencije"
      description="U vrijeme socijalnog distanciranja i drugih epidemioloških 
        mjera mnogi su sati logopedske terapije otkazani ili ostali na čekanju. 
        To se odnosi na djecu i odrasle kojima su već dijagnosticirane neke 
        komunikacijske poteškoće i s kojima se već prethodno radilo. Međutim, 
        što je s ranom intervencijom? Svi znamo i slušamo koliko su prve tri 
        godine djetetova života važne. Hoće li sada, kada je komunikacija uživo 
        ograničena, najviše stradati najdelikatniji kotačić sustava rehabilitacije, 
        a to je rana intervencija?"
      headline="Teleterapija kod rane intervencije"
    >
      <Text as="p">
        U vrijeme socijalnog distanciranja i drugih epidemioloških mjera mnogi
        su sati logopedske terapije otkazani ili ostali na čekanju. To se odnosi
        na djecu i odrasle kojima su već dijagnosticirane neke komunikacijske
        poteškoće i s kojima se već prethodno radilo. Međutim, što je s ranom
        intervencijom? Svi znamo i slušamo koliko su prve tri godine djetetova
        života važne. Hoće li sada, kada je komunikacija uživo ograničena,
        najviše stradati najdelikatniji kotačić sustava rehabilitacije, a to je
        rana intervencija? Hoće li se roditeljima, ako kod djeteta primijete
        nešto što ih zabrinjava, vrijeme oduljiti puno više nego što bi smjelo?
      </Text>
      <Text as="p">
        U novije doba jako se efikasnim alatom, u nemogućnosti fizičkih susreta,
        pokazala teleterapija. To je terapija na daljinu uz pomoć
        videokonferencijskih alata koja se izvodi preko računala ili tableta.
        Nakon posla od kuće i škole na daljinu, i logopedska je terapija sve
        više zaživjela u virtualnom prostoru, licem u lice s terapeutom, a opet
        na sigurnoj udaljenosti korisnikova doma. Više o teleterapiji možete
        pročitati i u našem{' '}
        <Link
          to="https://kokolingo.hr/edukativni-kutak/logopedska-terapija-na-daljinu"
          rel="noopener noreferrer"
          target="_blank"
          hrefLang="hr"
          title="Logopedska terapija na daljinu"
        >
          <b>edukativnom kutku</b>
        </Link>
        .
      </Text>
      <BlogImage
        imgAlt="Logopedska terapija na daljinu"
        imgTitle="Logopedska terapija na daljinu"
        imgSrc={speechTherapy1}
      />
      <Text as="p">
        Dok za redovite logopedske tretmane ovakav način rada postaje sve
        uobičajeniji, još uvijek se vrlo malo koristi u ranoj intervenciji ili
        pak sami korisnici drže da nije adekvatan za te svrhe. Međutim, rana
        intervencija također može vrlo lijepo zaživjeti kroz sredstva i alate
        teleterapije i u najranijoj dobi.
      </Text>
      <Text as="p">
        Ako mislite da je vaše dijete premaleno i neće se moći koncentrirati
        pred računalom tijekom cijelog jednog sata, imate pravo, ali to je
        također točno i za klasične logopedske tretmane. Zato će i teleterapija,
        baš kao i klasična terapija, biti prilagođena najmlađima, a najvažnija
        je pritom suradnja s roditeljima ili skrbnicima djeteta. Tako će oni,
        tijekom ili prije same terapije, dobiti upute što raditi s djetetom i
        kako će sat biti organiziran, kao i mnoge korisne savjete za rad s
        djecom izvan ugovorenih termina. Možda vas iznenadi dobra reakcija
        djeteta na novu osobu i novu aktivnost iz sigurnosti vlastitoga doma,
        dok je okruženo bliskim osobama i poznatim stvarima.
      </Text>
      <BlogImage
        imgAlt="Logopedska terapija na daljinu"
        imgTitle="Logopedska terapija na daljinu"
        imgSrc={speechTherapy2}
      />
      <Text as="p">
        Rad na daljinu pruža i dodatne prednosti, jer smanjuje eventualne
        troškove prijevoza i štedi vrijeme pa će biti jako koristan za djecu u
        udaljenim sredinama, izvan većih gradskih centara, koja bi morala
        putovati na logopedske tretmane. Također, susrete s logopedom bit će
        jednostavnije uklopiti u rasporede mališana, kojima odlazak na terapiju
        često prekida uobičajeni ritam hranjenja, spavanja ili pak moraju dulje
        putovati do svog logopeda pa često dolaze nervozna ili uspavana. Bit će
        i manje otkazivanja termina zbog lošeg vremena, bolesti, nemogućnosti
        uklapanja u raspored i slično.
      </Text>
      <Text as="p">
        Kod samih susreta s logopedom, u rad se jako lijepo može uklopiti i
        digitalna logopedska vježbenica <BlogLink>Kokolingo</BlogLink>. Ona
        terapeutu pruža mogućnost da ozbiljne zadatke obavlja u djeci
        primamljivom svijetu priča te bira materijale koji mu u tom trenutku
        najviše odgovaraju. <BlogLink>Kokolingo</BlogLink> također terapeutu
        štedi glas, a djetetu pruža dodatnu motivaciju. Jako je dobar za vježbe
        kod kuće kada sat završi, a uvjereni smo da će vam vaš logoped pokazati
        i kako ga primijeniti na neke sasvim nove načine, kojih se sami možda ne
        biste dosjetili.
      </Text>
      <BlogImage
        imgAlt="Kokolingo aplikacija"
        imgTitle="Kokolingo aplikacija"
        imgSrc={kokolingo}
      />
      <Text as="p">
        Kod svake važne stvari najvažniji je prvi korak. Zato se ne brinite i ne
        ustručavajte; ako posumnjate da razvoj vašeg djeteta možda ne slijedi
        pravila koja biste očekivali, da govor kasni, ako vam se čini da dijete
        možda ima artikulacijske poteškoće, napravite taj prvi najvažniji korak
        – pitajte. Raspitajte se, podijelite svoju zabrinutost, informirajte se
        na relevantnim izvorima i možda ćete, u suradnji sa stručnjakom, na
        kraju zaključiti da je baš teleterapija pravo rješenje za vas.
      </Text>
      <BlogLiterature className="blog__credits">
        <ul>
          <li>
            <a
              href="https://therapyworks.com/blog/the-benefits-of-teletherapy-for-early-intervention/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <b>
                https://therapyworks.com/blog/the-benefits-of-teletherapy-for-early-intervention/
              </b>
            </a>
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default TeletherapyInEarlyIntervention;
